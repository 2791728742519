import React, { useEffect, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import MenuMobile from "./MenuMobile";
import appendQueryString from "../utils/query";
import Img from "gatsby-image";

const Header = (props) => {
  const [menuActive, setMenuActive] = useState(false);
  const [isTop, setIsTop] = useState(true);
  
  useEffect(() => {
    if (typeof window === undefined) return;
    setIsTop(window.scrollY < 20);
    window.addEventListener("scroll", function (a) {
      setIsTop(window.scrollY < 20);
    });
  }, []);

  const toggleMenu = (menuActive) => {
    setMenuActive(!menuActive);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          file(extension: { eq: "png" }, name: { eq: "logo" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      `}
      render={(data) => (
        <div className={`header ${isTop ? "blend" : "solid"}`}>
          <Link to={appendQueryString("/")}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="EcoEnergy logo"
              className="logo"
            />
          </Link>
          <MenuMobile active={menuActive} />
          <Menu />
          <Hamburger toggleMenu={toggleMenu} show={!isTop} />
        </div>
      )}
    />
  );
};

export default Header;
