import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import appendQueryString from "../utils/query";

const Menu = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  let currentPath;
  let activeLink;
  if (typeof window != "undefined" && window.location) {
    currentPath = window.location.pathname;
    activeLink = currentPath.substr(0, currentPath.search(/.\//) + 2);
  }
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {menuLinks.map((link) => (
          <li
            key={link.name}
            className={activeLink === link.link ? "active" : ""}
          >
            <Link to={appendQueryString(link.link)}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={(data) => <Menu data={data} />}
  />
);
