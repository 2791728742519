import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const SEO = ({
  title, description, imageUrl, path,
}) => (
    <StaticQuery
      query={detailsQuery}
      render={(data) => (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            titleTemplate={`%s - ${data.site.siteMetadata.title}`}
            >
            <title>{title}</title>
            <meta name="title" content={`${title} - ${data.site.siteMetadata.title}`} />
            <meta name="description" content={description} />

            {/* OpenGraph/Facebook */}
            <meta property="og:site_name" content={data.site.siteMetadata.title} />
            <meta property="og:title" content={`${title} - ${data.site.siteMetadata.title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}${imageUrl}`} />
            <meta property="og:url" content={`${data.site.siteMetadata.siteUrl}${path}`} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={`${title} - ${data.site.siteMetadata.title}`} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={`${data.site.siteMetadata.siteUrl}${imageUrl}`} />
            <meta property="twitter:url" content={`${data.site.siteMetadata.siteUrl}${path}`} />

            <link rel="icon" type="image/png" href={`${data.site.siteMetadata.siteUrl}/assets/logo-mobile.png`} />
            <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${path}`} />
          </Helmet>
      )}
    />
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  path: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  imageUrl: '/assets/logo.png',
};

export default SEO;

const detailsQuery = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
